/**
 * The structure of this file was copied from the MDI (Material Design Icons) file in Vuetify's
 * built-in icon set.
 *
 * @see https://github.com/vuetifyjs/vuetify/blob/cce94274749dd9e9fbd14bc94a7bd8f6a0a0ce05/packages/vuetify/src/iconsets/mdi.ts
 */
import type { IconAliases, IconSet } from "vuetify";
import { VClassIcon } from "vuetify/components";
import arrowLeft from "virtual:icons/won/arrow-left.svg";
import arrowRight from "virtual:icons/won/arrow-right.svg";
import arrowUp from "virtual:icons/won/arrow-up.svg";
import { aliases as mdiAliases } from "vuetify/iconsets/mdi-svg";
import back from "virtual:icons/won/back.svg";
import goBackward15 from "virtual:icons/won/go-backward-15.svg";
import goForward15 from "virtual:icons/won/go-forward-15.svg";
import chat from "virtual:icons/won/chat.svg";
import chevronDown from "virtual:icons/won/chevron-down.svg";
import chevronLeft from "virtual:icons/won/chevron-left.svg";
import chevronLeftCircle from "virtual:icons/won/chevron-left-circle.svg";
import chevronUp from "virtual:icons/won/chevron-up.svg";
import close from "virtual:icons/won/close.svg";
import closeCircle from "virtual:icons/won/close-circle.svg";
import cog from "virtual:icons/won/cog.svg";
import forward from "virtual:icons/won/forward.svg";
import { h } from "vue";
import lightbulb from "virtual:icons/won/lightbulb.svg";
import logout from "virtual:icons/won/logout.svg";
import microphone from "virtual:icons/won/microphone.svg";
import more from "virtual:icons/won/more.svg";
import pause from "virtual:icons/won/pause.svg";
import people from "virtual:icons/won/people.svg";
import play from "virtual:icons/won/play.svg";
import plus from "virtual:icons/won/plus.svg";
import refresh from "virtual:icons/won/refresh.svg";
import removeSign from "virtual:icons/won/remove-sign.svg";
import sparkles from "virtual:icons/won/sparkles.svg";
import trashCan from "virtual:icons/won/trash-can.svg";
import volumeHigh from "virtual:icons/won/volume-high.svg";
import volumeOff from "virtual:icons/won/volume-off.svg";

const aliases: IconAliases = {
  /**
   * The following are default icons from Material Design that are bundled with Vuetify. The aliases
   * are required for custom IconSets in Vuetify. Replace as Wonder adds custom icons.
   *
   * @see Vue's built-in aliases: https://vuetifyjs.com/en/features/icon-fonts/#built-in-aliases
   */
  ...mdiAliases,

  back,
  chat,
  chevronLeftCircle,
  close,
  closeCircle,
  cog,
  collapse: chevronUp,
  expand: chevronDown,
  forward,
  goBackward15,
  goForward15,
  left: arrowLeft,
  lightbulb,
  logout,
  microphone,
  more,
  pause,
  people,
  play,
  plus,
  prev: chevronLeft,
  refresh,
  removeSign,
  right: arrowRight,
  sortAsc: arrowUp,
  sparkles,
  trashCan,
  volumeHigh,
  volumeOff,
};

const won: IconSet = {
  component: (props: any) => h(VClassIcon, props),
};

export { aliases, won };
